<template>
    <div>
        <input hidden type="text" class="form-control" id="TaxNumberId" v-model="TaxNumberId">
        <div class="modal fade" id="taxInvoiceModal" tabindex="-1" aria-labelledby="taxInvoiceModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="taxInvoiceModalLabel" class="font-weight-bold">{{ ModalTitle }}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Min Tax Number</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="MinTaxNumber" v-model="MinTaxNumber" class="font-weight-bold" Type="number"/>
                                    <label id="errorMinTaxNumber" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Max Tax Number</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="MaxTaxNumber" v-model="MaxTaxNumber" class="font-weight-bold" Type="number"/>
                                    <label id="errorMaxTaxNumber" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <div v-if="this.SaveType !== 'Add'" >
                                <CRow>
                                    <CCol>
                                        <input type="checkbox" v-model="Active"/> <label>Active</label>
                                        <label id="errorActive" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>
                                </CRow>
                            </div>
                            <br>
                            <label id="errorTaxNumberGeneral" class="form-error" style="display: none; color: red;"></label>
                            <br>

                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import TaxInvoiceService from '../Script/TaxInvoiceServices.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'TaxInvoiceForm',
    props: ['reload'],
    components: {
    },
    data: function () {
        return {
            ModalTitle : '',

            TaxNumberId : '',
            MinTaxNumber: 0,
            MaxTaxNumber: 0,
            IsUsed: false,
            Active: false,

            Error: 0,
            SaveType: '',
        }
    },
    methods: {
        addClick() {  
            $(".form-error").css("display", "none");
            $(".save-button").show();

            this.ModalTitle = 'Add Tax Invoice: ';   
            this.TaxNumberId = '';
            this.MinTaxNumber = 0;
            this.MaxTaxNumber = 0;

            this.IsUsed = false;
            this.Active = false;

            this.SaveType = 'Add';
            window.$('#taxInvoiceModal').modal('show');
        },
        editClick(data, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            
            this.ModalTitle = 'Edit Tax: ';
                        
            this.TaxNumberId = data.tax_number_id;
            this.MinTaxNumber = data.min_tax_number;
            this.MaxTaxNumber = data.max_tax_number;

            this.IsUsed = data.is_used;
            this.Active = data.active;

            this.SaveType = 'Edit';
            
            if(View){
                $(".save-button").hide();
            }
            
            window.$('#taxInvoiceModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if (parseInt(this.MinTaxNumber) == 0 || toString(this.MinTaxNumber) == null) {
                this.errorShow('errorMinTaxNumber');
            }

            if (parseInt(this.MaxTaxNumber) == 0 || toString(this.MaxTaxNumber) == null) {
                this.errorShow('errorMaxTaxNumber');
            }

            // var oldData = await TaxInvoiceService.getIsDefaultValidation();
            // var hashMap = [];
            // oldData.forEach(el=>{
            //     hashMap[el.id]= el
            // })
            
            // if(hashMap[this.TaxNumberId].is_default == true &&  this.Default == false){
            //     this.Error++;
            //     this.$swal("Error", "Default tidak boleh di uncheck", "error");
            // }
        },
        async saveClick() {
            this.inputValidation();
            
            if(this.Error == 0){
                //Add
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const taxInvoiceData = {
                        created_at: "",
                        last_update: "",
                        min_tax_number: this.MinTaxNumber,
                        max_tax_number: this.MaxTaxNumber,
                        current_tax_number: this.MinTaxNumber - 1,
                        is_used: this.IsUsed,
                        active: this.Active
                    };

                    const variables = {
                        data : taxInvoiceData
                    }

                    TaxInvoiceService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#taxInvoiceModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                //Edit
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const taxInvoiceData = {
                        created_at: "",
                        last_update: "",
                        min_tax_number: this.MinTaxNumber,
                        max_tax_number: this.MaxTaxNumber,
                        current_tax_number: this.MinTaxNumber - 1,
                        is_used: this.IsUsed,
                        active: this.Active
                    };

                    const variables = {
                        id : parseInt(this.TaxNumberId),
                        data : taxInvoiceData
                    }

                    TaxInvoiceService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#taxInvoiceModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>
