import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class TaxInvoiceService {
    readTaxInvoiceNumber() {
        var query = `
            query {
                GetMasterTaxNumber {
                    created_at
                    last_update
                    tax_number_id
                    min_tax_number
                    max_tax_number
                    current_tax_number
                    removed_tax_number
                    is_used
                    active
                } 
            }
        `;
        return query;
    }

    async getIsDefaultValidation() {
        var query = gql`
            query {
                GetMasterTax {
                    id
                    is_default
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var ppnTaxData = [];
        for (let i = 0; i < result.data.GetMasterTax.length; i++) {
            var data = { 
                id: result.data.GetMasterTax[i].id,
                is_default: result.data.GetMasterTax[i].is_default
            }
            ppnTaxData.push(data);
        }
        return ppnTaxData
    }

    async deleteQuery(variables){
        var query = gql`
            mutation ($id:Int!) {
                DeleteMasterTaxNumber (ID:$id)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async addQuery(variables){
        var query = gql`
            mutation ($data: NewMasterTaxNumber!) {
                CreateMasterTaxNumber (New: $data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        var query = gql`
            mutation ($id:Int!, $data:NewMasterTaxNumber!) {
                UpdateMasterTaxNumber (ID:$id, New:$data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}


export default new TaxInvoiceService();
